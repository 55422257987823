<template>
  <div
    v-if="item"
    class="stocks-products stocks-products-full"
  >
    <div class="stocks-products__slide-discount">
      <img
        class="stocks-products__slide-mob"
        :src="$helpers.getAbsolutePath(item.attributes.backgound_mob.data.attributes.url)"
        alt="slide image"
      />
      <img
        class="stocks-products__slide-desk"
        :src="$helpers.getAbsolutePath(item.attributes.backgound_desktop.data.attributes.url)"
        alt="slide image"
      />
      <div class="container">
        <div class="stocks-products-full__slide-content">
          <h1
            :style="getTitleColor(item)"
            class="stocks-products-full__slide-title-discount"
          >
            {{ item.attributes.label }}
          </h1>
          <p
            :style="getTitleColor(item)"
            class="stocks-products-full__slide-text-discount"
          >
            {{ item.attributes.slogan }}
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="item.attributes.conditions"
      class="stocks-products-full__card"
    >
      <div class="container">
        <div class="stocks-products-full__inner">
          <div class="stocks-products-full__header">
            <div class="stocks-products-full__top">
              <h1 class="stocks-products-full__title title">{{ $t("stocks.conditions") }}</h1>
            </div>
            <span class="stocks-products-full__date">
              {{ $t("stocks.duration") }}:
              {{ getDatePeriod(item) }}
            </span>
            <!-- <span class="stocks-products-full__draw">
              {{ $t("draw") }}
            </span> -->
          </div>
          <a
            v-if="item.attributes.link_to_rules && !$isMobile()"
            :href="item.attributes.link_to_rules"
            class="stocks-products-full__rules"
            target="_blank"
            >{{ $t("stocks.rules") }}</a
          >
        </div>

        <div class="stocks-products-full__card-content">
          <div class="stocks-products-full__list">
            <vue-markdown
              class="stocks-products-full__number-list"
              :source="item.attributes.conditions"
              style="white-space: pre-line"
              :anchorAttributes="{ target: '_blank' }"
            >
              {{ item.attributes.conditions }}
            </vue-markdown>
          </div>
        </div>
        <a
          v-if="item.attributes.link_to_rules && $isMobile()"
          :href="item.attributes.link_to_rules"
          class="stocks-products-full__rules"
          target="_blank"
          >{{ $t("stocks.rules") }}</a
        >
      </div>
    </div>
    <div
      v-if="products && products.length"
      class="stocks-products-participating"
    >
      <div class="container">
        <h2 class="stocks-products-participating__title title">
          {{ $t("productsPromotion") }}
        </h2>

        <div class="stocks-products-participating__items">
          <product-card
            v-for="item in products"
            :key="item.id"
            :item="item"
            class="stocks-products-participating__item"
          />
        </div>
      </div>
    </div>

    <div
      v-if="stocks.length"
      class="stocks-products__content"
    >
      <div class="container">
        <div class="stocks-products__suggestions">
          <div class="stocks-products__top header-block">
            <h2 class="title">{{ $t("stocks.another") }}</h2>
          </div>

          <stocks-list
            v-if="stocks"
            :items="stocks"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import VueMarkdown from "vue-markdown"
import { getPromos, getPromo } from "@/api"
import { getProductsByFilters, getStocksProducts } from "@/api/products"
export default {
  name: "FullSpecial",
  components: {
    VueMarkdown,
    ProductCard: () => import("@/components/products/ProductCard.vue"),
    StocksList: () => import("@/components/stocks/StocksList.vue"),
  },
  data() {
    return {
      item: null,
      products: null,
      stocks: [],
      stocksPagination: { page: 1, pageSize: 1500, isLastPage: false },
      currentStock: null,
      googlePlayLink: null,
      appStoreLink: null,
      appGalleryLink: null,
      stocksTypes: {
        action_loyalty: {
          image: "/stocks/magnum-club.svg",
          isClub: true,
          isRaffle: false,
        },
        action_all: {
          image: "/stocks/magnum.svg",
          isClub: false,
          isRaffle: false,
        },
        raffle_loyalty: {
          image: "/stocks/magnum-club.svg",
          isClub: true,
          isRaffle: true,
        },
        raffle_all: {
          image: "/stocks/magnum.svg",
          isClub: false,
          isRaffle: true,
        },
        action_loyalty_discount: {
          image: "/stocks/magnum-club.svg",
          isClub: true,
          isRaffle: false,
        },
        action_all_discount: {
          image: "/stocks/magnum.svg",
          isClub: false,
          isRaffle: false,
        },
        raffle_loyalty_discount: {
          image: "/stocks/magnum-club.svg",
          isClub: true,
          isRaffle: true,
        },
        raffle_all_discount: {
          image: "/stocks/magnum.svg",
          isClub: false,
          isRaffle: true,
        },
      },
    }
  },
  created() {
    getPromos({ type: "current" }, { page: 1, pageSize: 3 }, this.$route.params.id).then(
      (stocks) => {
        this.stocks = stocks.data
      },
    )

    getPromo(this.$route.params.id).then((stock) => {
      this.item = stock.data
      if (this.item.attributes.promo_type === "only_discount") {
        this.$router.push("/stocks/products/" + this.item.id)
        return
      }
      this.currentStock = this.stocksTypes[this.item.attributes.promo_type]
    })
    getStocksProducts(this.$route.params.id).then((products) => {
      this.products = products.data
    })
  },

  computed: {
    ...mapState("cityModule", ["selectedCity"]),
  },
  watch: {
    "$route.params.id": {
      deep: true,
      handler() {
        // getPromo(id).then((stock) => {
        //   this.item = stock.data;
        // });
        this.getFullStock()
      },
    },
    selectedCity: {
      handler(selectedCity) {
        this.getFullStock(selectedCity.id)
      },
    },
  },

  methods: {
    seeAll() {
      this.stocksPagination.page++
      this.getFullStock()
    },
    getFullStock(cityId) {
      getPromo(this.$route.params.id).then((stock) => {
        this.item = stock.data
        if (this.item.attributes.promo_type === "only_discount") {
          this.$router.push("/stocks/products/" + this.item.id)
          return
        }
        this.currentStock = this.stocksTypes[this.item.attributes.promo_type]
        if (this.item.attributes?.discount?.data?.id) {
          getProductsByFilters(
            {
              categories: [],
              discountTypes: [this.item?.attributes?.discount?.data?.id],
              shopTypes: [],
              cityId,
            },
            { field: "discount", sortType: "desc" },
            {
              page: this.stocksPagination.page,
              pageSize: this.stocksPagination.pageSize,
            },
            this.selectedCity?.id,
          ).then((response) => {
            if (this.stocksPagination.page === response.meta.pagination.page) {
              this.stocksPagination.isLastPage = true
            }
          })
        }
      })
      getStocksProducts(this.$route.params.id).then((products) => {
        this.products = products.data
      })
      getPromos({ type: "current" }, { page: 1, pageSize: 3 }, this.$route.params.id).then(
        (stocks) => {
          this.stocks = stocks.data
        },
      )
    },
    getBigImage(item) {
      return this.$helpers.getAbsolutePath(
        item?.attributes?.backgound_desktop?.data?.attributes?.url,
      )
    },
    getSmallImage(item) {
      return this.$helpers.getAbsolutePath(item?.attributes?.backgound_mob?.data?.attributes?.url)
    },
    getDateStart(item) {
      const dateFromStr = new Date(item.attributes?.date_start)
      return new Intl.DateTimeFormat("ru-Ru", {
        month: "numeric",
        day: "numeric",
      }).format(dateFromStr)
    },
    getDateEnd(item) {
      const dateFromStr = new Date(item.attributes?.date_end)
      return new Intl.DateTimeFormat("ru-Ru", {
        month: "numeric",
        day: "numeric",
      }).format(dateFromStr)
    },

    getImage(url) {
      return require("@/assets/img" + url)
    },

    getDatePeriod(item) {
      if (Number(this.getDateStart(item)) === Number(this.getDateEnd(item))) {
        return this.getDateStart(item)
      }
      return this.getDateStart(item) + " - " + this.getDateEnd(item)
    },

    getTitleColor(item) {
      return "color:" + item?.attributes?.bg_color
    },
  },
}
</script>

<style lang="scss">
.app-info {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  position: relative;
  &-qr {
    width: 150px;
    height: 150px;
    @media screen and (max-width: 640px) {
      display: none;
    }
    &-decore {
      position: absolute;
      top: -6px;
      right: -6px;
    }
  }
}

.product-block__bonuses {
  top: 50px;
}

.app-list-link {
  width: 135px;
  height: 39px;
  object-fit: cover;
}

.magnum-club__btn {
  display: block;

  & + & {
    margin-top: 15px;
  }
}

.stocks-products__slide-mob,
.stocks-products__slide-desk {
  height: 100%;
  width: 100%;
}
.stocks-products__slide-mob {
  display: none;
}
.stocks-products-participating__item {
  width: 295px !important;
  height: 500px !important;
  border: 1px solid #f3f3f3 !important;
  border-radius: unset !important;
}
@media (max-width: 1030px) {
  .app-info-qr-decore {
    right: -35px;
  }
}

@media (max-width: 770px) {
  .app-info-qr-decore {
    right: 50%;
    transform: translateX(165px);
  }
}

@media (max-width: 700px) {
  .stocks-products-full__slide-content {
    top: 10px;
  }
  .stocks-products-full__slide-title-discount {
    margin-bottom: 10px;
    font-size: 20px;
  }
  .stocks-products-full__slide-text-discount {
    font-size: 18px;
  }
}

@media (max-width: 640px) {
  .app-info-qr-decore {
    display: none;
  }
  .stocks-products-participating__item {
    width: 100% !important;
    height: auto !important;
    border-right: none !important;
    border-left: none !important;
  }
}

@media (max-width: 550px) {
  .stocks-products__slide-mob {
    display: block;
  }
  .stocks-products__slide-desk {
    display: none;
  }
  .stocks-products-full__slide-content {
    top: 50px;
  }
  .stocks-products-full__slide-title-discount {
    margin-bottom: 10px;
    font-size: 30px;
  }
  .stocks-products-full__slide-text-discount {
    font-size: 25px;
  }

  .product-block__bonuses {
    top: 40px;
  }
}

@media (max-width: 400px) {
  .stocks-products-full__slide-content {
    top: 50px;
  }
  .stocks-products-full__slide-title-discount {
    margin-bottom: 10px;
    font-size: 25px;
  }
  .stocks-products-full__slide-text-discount {
    font-size: 18px;
  }
}
</style>
